@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Manrope:wght@200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.containerFlow {
  @apply flex flex-col w-full min-h-screen justify-between border-teal-800;
}

.containerCol {
  @apply w-full flex flex-col;
}


.gridSystem {
  @apply grid gap-6 sm:grid-cols-2 lg:grid-cols-3 w-full border-teal-500;
}

.lineUp {
  @apply appearance-none block w-full px-3 py-2 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm;
}

input[disabled] {
  opacity: 0.5 !important;
  user-select: none !important;
}

@layer utilities {
  .scrollbar-gone::-webkit-scrollbar {
    width: 0px;
  }
}

.font-montserrat{
  font-family: 'Montserrat', sans-serif;
}
body{
  font-family: 'Montserrat', sans-serif;
}
